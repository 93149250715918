import React from 'react';
import {  VideoCameraIcon, QuestionMarkCircleIcon, RefreshIcon, CalendarIcon } from '@heroicons/react/solid';
import { NoResultsMsg } from "../../common/";

const configDate = {
    weekday: 'long', 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric', 
    hour: 'numeric', 
    minute:'numeric', 
    second: 'numeric'
};
const iconList = {
    'NO-TYPE': QuestionMarkCircleIcon ,
    'CAMERA-SERVICE': VideoCameraIcon,
    'SYNC-SERVICE': RefreshIcon,
};
  
const List = ({ logs, loading }) => {
    return (
        <>
            <div className={`${loading ? 'block' : 'hidden'} loading`}/>
            <ul className="divide-y min-h-full divide-gray-200">
                { logs === "empty" ? <NoResultsMsg/>
                    : logs?.sort((a,b) =>  b.createdAt.seconds - a.createdAt.seconds).map((log) => {
                        const Icon = iconList[log.type] ? iconList[log.type] : iconList['NO-TYPE'];
                        return(
                            <li key={Math.random().toString(36).substring(7)}>
                                <div className="block w-full">
                                    <div className="px-4 py-3 flex items-center sm:px-6">
                                        <Icon className="flex-shrink-0 mr-5 h-7 w-7 text-gray-400 " aria-hidden="true" />
                                        <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                            <div className="truncate">
                                                <div className="flex text-sm">
                                                    <p className="font-medium text-indigo-600 truncate">{log.message}</p>
                                                </div>
                                                <div className="flex mt-2  items-center text-sm text-gray-500">
                                                    <p className="flex">
                                                        <CalendarIcon className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" aria-hidden="true" />
                                                        <time>{new Date(log.createdAt.seconds * 1000).toLocaleDateString("es-ES",configDate) }</time>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        );
                    })
                }
            </ul>
        </>
    );
};

export default List;
