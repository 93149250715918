import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../config/firebase';

const AuthContext = createContext();

export function useAuth(){
    return useContext(AuthContext);
}

export function AuthProvider({ children }){
    const [currentUser, setCurrentUser] = useState(() =>
        JSON.parse(localStorage.getItem('authUser') || null),
    );

    function login(email, password) {
        return auth.signInWithEmailAndPassword(email,password);
    }
    function logout() {
        return auth.signOut();
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async user => {
            if(user){
                localStorage.setItem('user', JSON.stringify(user));
                setCurrentUser(user);
            } else {
                localStorage.removeItem('user');
                setCurrentUser(null);
            }

        });
        return unsubscribe;
    }, []);

    const value ={
        currentUser,
        login,
        logout
    };


    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}
