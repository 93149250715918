import React from "react";
import {
    BrowserRouter as Router,
    Switch,
} from "react-router-dom";

import LoginPage from '../views/Login/Login.index';
import { ProtectedRoute, PublicRoute } from "./ProtectRoute";
import Dashboard from "./Dashboard";
import { AuthProvider } from '../context/AuthContext';

function App() {
    return (
        <AuthProvider>  
            <Router >
                <Switch>
                    <ProtectedRoute path="/dashboard">
                        <Dashboard/>
                    </ProtectedRoute>
                    <PublicRoute exact path="/">
                        <LoginPage/>
                    </PublicRoute>
                    {/* <RedirectRoute exact path="/"/> */}
                </Switch>
            </Router>
        </AuthProvider>
    );
}

export default App;
