import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const ProtectedRoute = ({ children, ...rest }) => {
    return (
        <Route {...rest} render={({ location }) => localStorage.getItem('user')
            ? ( children ) 
            : ( <Redirect to={{ pathname: "/", state: { from: location } }}/>)
        }
        />
    );
};
export const PublicRoute = ({ children, ...rest }) => {
    return (
        <>
            <Route {...rest} render={({ location }) => localStorage.getItem('user') 
                ? ( <Redirect to={{ pathname: "/dashboard", state: { from: location } }}/>)
                : ( children )
            }
            />
        </>
    );
};
