import React, { Fragment, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Transition, Disclosure } from "@headlessui/react";
import sub from 'date-fns/sub';
import * as Yup from "yup";
import { FilterIcon, RefreshIcon, VideoCameraIcon, QuestionMarkCircleIcon } from "@heroicons/react/solid";
import { firestore } from "../../config/firebase";
import { DevicesList, LogsList } from "../../components/Layout/Logs";
import { SearchInput, AlertError } from "../../components/common";
import { FilterOptions } from "../../components/Layout/Logs/FilterGroup/FilterOptions";

const devicesList = [
    { name: "Todos", value: null, icon: QuestionMarkCircleIcon },
    { name: "Camaras", value: "CAMERA-SERVICE", icon: RefreshIcon },
    { name: "Sync", value: "SYNC-SERVICE", icon: VideoCameraIcon },
];
const amountList = [
    { name: "20", value: 20 },
    { name: "30", value: 30 },
    { name: "50", value: 50 },
    { name: "100", value: 100 },
];
const orderList = [
    { name: "Ascendete", value: "asc" },
    { name: "Descendente", value: "desc" },
];

const SignupSchema = Yup.object().shape({
    deviceId: Yup.string().required('Debes de seleccionar un dispositivo'),
});


const initialValues = {
    deviceId: "",
    startDate: sub(new Date(), { days: 10 }),
    endDate: new Date(),
    orderBy: orderList[1],
    typeLogs: devicesList[0],
    amountLogs: amountList[0],
    search:''
};
const Index = () => {

    const [devices, setDevices] = useState([]);
    const [logs, setLogs] = useState([]);
    const [loadingLogs, setLoadingLogs] = useState(false);
    const [loadingDevices, setLoadingDevices] = useState(false);

    const getLogs = async({ deviceId, 
        startDate, 
        endDate, 
        orderBy, 
        typeLogs, 
        amountLogs,
    }) => {
    
        setLogs([]);
        setLoadingLogs(true);
    
        let query = firestore.collection('devices').doc(deviceId).collection('logs')
            .limit(amountLogs.value)
            .orderBy('createdAt', orderBy.value)
            .where('createdAt', '>=', new Date(startDate))
            .where('createdAt', '<=', new Date(endDate));
        if(!typeLogs){
            query = query.where('type',"==", typeLogs.value);
        } 
        let res = [];
        try {
            res = await query.get();
        } catch (error) {
            console.error(error);
        }
        if(res.empty) setLogs("empty");
        res.forEach(ref => setLogs(logs => [ref.data(), ...logs]));
        setLoadingLogs(false);
    };

    const getDevices = async () => {
        setDevices([]);
        setLoadingDevices(true);
        let res = [];
        try {
            res = await firestore.collection("devices").get();
        } catch (error) {
            console.error(error);
        }
        res.docs.forEach((ref) => setDevices((devices) => [ref.data(), ...devices]));
        setLoadingDevices(false);
    };

    useEffect(() => {
        getDevices();
    }, []);

    return (
        <>
            <Formik 
                onSubmit={(values) => getLogs(values)}  
                initialValues={initialValues} 
                validationSchema={SignupSchema}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({ handleSubmit, errors }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="p-5 grid grid-cols-5 gap-5 md:h-screen ">
                            <div className="col-span-5 md:col-span-2 pt-5 pb-10 bg-white shadow rounded-lg max-h-screen overflow-auto">
                                <div className="flex px-5 items-center">
                                    <dt className="items-center mr-8 text-sm font-medium text-gray-500 truncate">
                                        Dispositivos
                                    </dt>
                                    <SearchInput
                                        placeholder="Escribe para empezar a buscar"
                                        name="search"
                                        className="relative w-full text-gray-400 focus-within:text-gray-600"
                                    />
                                </div>
                                <dd className="overflow-y-auto mt-1 text-3xl font-semibold text-gray-900">
                                    <DevicesList
                                        loading={loadingDevices}
                                        devices={devices}
                                    />
                                </dd>
                            </div>
                            <div className="col-span-5 md:col-span-3 pt-5 pb-2 bg-white shadow rounded-lg overflow-hidden  max-h-screen">
                                <div className="  px-5 text-sm font-medium text-gray-500 w-full mx-auto bg-white rounded-2xl">
                                    <FilterGroup title="Registros" />

                                </div>
                                <AlertError name="deviceId" msg={errors.deviceId} />

                                <dd className="h-full pb-4 overflow-auto mt-1 text-3xl font-semibold text-gray-900">
                                    <LogsList loading={loadingLogs} logs={logs} />
                                </dd>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default Index;

const FilterGroup = ({ title }) => (
    <>
        <Disclosure>
            <div className="flex items-center place-content-between truncate">
                <span>{title}</span>
                <Disclosure.Button className="flex text-sm font-medium text-gray-500 border border-solid rounded border-gray-200 bg-gray-100 px-2 py-1">
                    <FilterIcon className=" h-5 w-5" />
                    <span>Filtrar registros</span>
                </Disclosure.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-in-out duration-400 transform"
                enterFrom="-translate-y-full opacity-0"
                enterTo="translate-y-0 opacity-0"
                leaveFrom="translate-y-100 opacity-0"
                leaveTo="-translate-y-full opacity-0"
            >
                {(ref) => (
                    <Disclosure.Panel ref={ref} className="grid grid-cols-4 auto-rows-auto gap-3 my-5 border border-solid rounded border-gray-200 p-5">
                        <FilterOptions devicesList={devicesList} amountList={amountList} orderList={orderList} />
                    </Disclosure.Panel>
                )}
            </Transition>
        </Disclosure>
    </>
);



