import { DocumentSearchIcon } from '@heroicons/react/outline';
import React from 'react';

const NoResultsMsg = () => {
    return (
        <div className="flex justify-center w-full pt-10 align-bottom rounded-lg  text-left overflow-hidden ">
            <div>
                <div className="mx-auto flex items-center justify-center ">
                    <DocumentSearchIcon className="h-12 w-12 text-gray-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            No se encontraron resultados.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NoResultsMsg;
