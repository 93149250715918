import React from 'react';
import { Link, useHistory } from "react-router-dom";
import { LogoutIcon } from "@heroicons/react/outline";
import { useAuth } from '../../../context/AuthContext';

const MenuTabs = ({ tabs }) => {
    const { logout } = useAuth();
    const history = useHistory();

    const handleLogout = async () =>{
        try{
            await logout();
            history.push('/');
        } catch(err) {
            console.error(err);
        }
    };

    return (
        <>
            <div className="w-full h-screen py-6 flex flex-col items-center">
                <div className="flex-shrink-0 flex items-center">
                    <img
                        className="h-8 w-auto"
                        src="https://prod-queoaccess-blog.s3.us-west-2.amazonaws.com/2021/03/Queo-Access-Blanco-Anaranjado_peq.png"
                        alt="Workflow"
                    />
                </div>
                <div className="flex flex-col flex-1 mt-6 w-full px-2">
                    {tabs.map((item) => (
                        <Link
                            key={item.name}
                            to={item.href}
                            className={` 
                  ${item.current 
                            ? 'bg-gray-800 text-white' 
                            : 'text-gray-100 hover:bg-gray-800 hover:text-white'}
                  ${item.key === "settings" ? 'mt-auto' : ""} 
                  group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium
                `}
                            aria-current={item.current ? 'page' : undefined}
                        >
                            <item.icon
                                className={`
                    ${item.current 
                            ? 'text-white' 
                            : 'text-gray-300 group-hover:text-white'}
  
                    h-6 w-6
                  `}
                                aria-hidden="true"
                            />
                            <span className="mt-2">{item.name}</span>
                        </Link>
                    ))}
                    <button
                        onClick={handleLogout}
                        className='mt-auto text-gray-100 hover:bg-gray-800 hover:text-white mt-autogroup w-full p-3 rounded-md flex flex-col items-center text-xs font-medium'>
                        <LogoutIcon
                            className='text-gray-300 group-hover:text-white h-6 w-6'
                            aria-hidden="true"
                        />
                        <span className="mt-2">Cerrar sesión</span>
                    </button>
                </div>
            </div>
        </>
    );
};

export default MenuTabs;
