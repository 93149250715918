import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from "yup";
import { useAuth } from '../../context/AuthContext';
import { useHistory } from 'react-router-dom';



const Index = () => {
    const history = useHistory();
    const { login } = useAuth();
    const [error, setError] = useState("");

    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Requiere un email'),
        password: Yup.string().required('Requiere contraseña')
    });
      
    const initialValues ={
        email: "",
        password: ""
    };
    
    const handleLogin = async ({ email, password }) =>{
        try{
            await login(email, password);
            history.push('/');
        } catch(err){
            switch (err) {
            case "auth/wrong-password":
                setError("Los datos ingresados no son válidos.");
                break;
            case "auth/user-not-found":
                setError("Los datos ingresados no son válidos.");
                break; 
            case "auth/too-many-requests":
                setError("Demasiados intentos, vuelve a intentarlo mas tarde.");
                break;         
            default:
                break;
            }
        }
    };

    return (
        <>
            <div className="min-h-screen bg-gray-700 flex">
                <div className="w-screen flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-10">
                    <div className="mx-auto w-full max-w-sm lg:w-96 bg-white rounded-xl p-10">
                        <div className="flex flex-col items-center">
                            <img
                                className="h-20 w-auto"
                                src="https://play-lh.googleusercontent.com/rgWUmpe-W684iLM4G6ijgdQtLyMew6TQmxIYEahjnL9xidfZs_yuBwG67DXrxwW0Zlg=s360-rw"
                                alt="Workflow"
                            />
                            <h2 className="mt-6 text-3xl font-extrabold text-gray-700">Inicio de sesión</h2>
                        </div>

                        <div className="mt-8 flex flex-col">
                            {error ? 
                                <span className="items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-red-100 text-red-800">
          
                                    <div className=" font-bold text-red-400">
            Error:
                                    </div>
                                    {error}
                                </span>
                                : ""
                            }
                            <Formik 
                                onSubmit={(values) => handleLogin(values)}  
                                initialValues={initialValues}
                                validationSchema={validationSchema} 
                            >
                                {({ handleSubmit, errors, isSubmitting, touched }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <div className="mt-6">
                                            <div className={`${errors.email && touched.email ? "ring-1 ring-red-600" : ""} relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-gray-600 focus-within:border-gray-600`}>
                                                <label
                                                    htmlFor="email"
                                                    className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                                                >
                  Correo
                                                </label>
                                                <Field
                                                    type="email"
                                                    name="email"
                                                    className="block w-full border-0 p-0 text-gray-900 focus:ring-0 sm:text-sm"
                                                    placeholder="Ingresa tu correo"
                                                />
                                            </div>
                                            <div className={`${errors.password && touched.password ? "ring-1 ring-red-600" : ""} relative border my-6 border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-gray-600 focus-within:border-gray-600`}>
                                                <label
                                                    htmlFor="password"
                                                    className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                                                >
                  Contraseña
                                                </label>
                                                <Field
                                                    type="password"
                                                    name="password"
                                                    className={` block w-full border-0 p-0 text-gray-900 bg-white focus:ring-0 sm:text-sm`}
                                                    placeholder="Ingresa tu contraseña"
                                                />

                                            </div>
                                            <div>
                                                <button
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                    className={`${isSubmitting ? 'cursor-not-allowed' : ' bg-white'}
                    w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-300 hover:text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                    `}
                                                >
       

                                                    {isSubmitting ? "Entrando..." : "Entrar"}
                                                </button>
                                            </div>
                                        </div>

                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
