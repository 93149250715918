import React from 'react';
import { SearchIcon } from '@heroicons/react/solid';
import { Field } from 'formik';

const SearchInput = ({ placeholder, className, name }) => {
    return (
        <div className="flex-1 flex">
            <div className={className}>
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                    <SearchIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                </div>
                <Field
                    name={name}
                    id={name}
                    className="h-full w-full border-transparent pl-8 pr-3 text-base text-gray-400 focus:text-black placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400"
                    placeholder={placeholder}
                    type="search"
                    autoComplete="off"
                />
            </div>
        </div>
    );
};

export default SearchInput;
