import React from 'react';
import { ChevronRightIcon, ChipIcon, OfficeBuildingIcon } from '@heroicons/react/solid';
import { useFormikContext } from 'formik';
import Tooltip from '@material-ui/core/Tooltip';

const List = ({ devices = [], loading }) => {
    const { setFieldValue, values } = useFormikContext();

    const devicesFiltered = devices.filter(e =>
        e?.propertyName?.toLowerCase().includes(values.search?.toLowerCase()) ||
        e?.name?.toLowerCase().includes(values.search?.toLowerCase()) ||
        e?.deviceId?.toLowerCase().includes(values.search?.toLowerCase()
        ));

    return (
        <>
            <div className={`${loading ? 'block' : 'hidden'} loading`} />
            <ul className=" divide-y  divide-gray-200 overflow-hidden">
                {devicesFiltered.map((device) => (
                    <li key={device.deviceId}>
                        <Tooltip title={`ID: ${device.deviceId}`}>
                            <button
                                className="w-full hover:bg-gray-200"
                                onClick={() => setFieldValue('deviceId', device.deviceId)}
                                type="submit"
                            >
                                <div className="px-4 py-4 flex items-center sm:px-6">
                                    <ChipIcon className="flex-shrink-0 mr-4 h-6 w-6 text-gray-400 " aria-hidden="true" />
                                    <div className="mr-auto w-auto min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                        <div className="truncate">
                                            <div className="flex flex-col items-start text-sm">
                                                <p className="font-bold text-gray-600 truncate">{device.name}</p>
                                                <div className="flex">
                                                    <div className="flex items-center">
                                                        <OfficeBuildingIcon className="flex-shrink-0 mr-1 h-4 w-4 text-gray-400" aria-hidden="true" />
                                                        <p className="font-medium text-gray-500 truncate">
                                                            {device.propertyName}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </div>
                                </div>
                            </button>
                        </Tooltip>
                    </li>
                ))}
            </ul>
        </>
    );
};

export default List;
