import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Menu from '../components/Layout/Menu/Menu';
import LogsPage from '../views/Logs/Logs.index';

const Dashboard = () => {

    return (
        <>
            <Menu>
                <Switch>
                    <Route exact path={`/dashboard`}>
                        <Redirect to="/dashboard/logs"/>
                    </Route>
                    <Route exact path={`/dashboard/logs`}>
                        <LogsPage/>
                    </Route>
                </Switch>
            </Menu>
        </>
    );
};

export default Dashboard;
