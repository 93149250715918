import React from 'react';
import {  CalendarIcon } from '@heroicons/react/outline';
import { useFormikContext } from 'formik';
import { format } from 'date-fns';

const InputDate = ({ name, id, placeholder, className, minDate, maxDate }) => {
    const { setFieldValue, values } = useFormikContext();
    return (
        <div className={className}>
            <input
                min={minDate}
                max={maxDate}
                type="datetime-local"
                name={name}
                id={id}
                value={format(new Date(values[name]), 'yyyy-MM-dd\'T\'HH:mm')}
                onChange={(e) => setFieldValue(name, e.target.value)}
                className="px-3 py-1 focus:ring-indigo-500 focus:border-indigo-500 focus:outline-none block w-full pr-10 sm:text-sm border border-gray-200 rounded-md"
                placeholder={placeholder}
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
        </div>
    );
};

export default InputDate;
