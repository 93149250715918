import React from "react";
import { ArrowRightIcon } from "@heroicons/react/solid";
import { useFormikContext } from "formik";
import { InputDate, InputDropBox } from "../../../common";

export const FilterOptions = ({ devicesList, amountList, orderList }) =>{
    const { isSubmitting, values } = useFormikContext();
    
    return(
        <>
            <div className="col-span-4">
                <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                    Rango de fechas
                </label>
                <div className="flex items-center flex-wrap">
                    <InputDate
                        className="w-52 mt-1 relative rounded-md shadow-sm"
                        name="startDate"
                        id="inputDateInit"
                        placeholder="Fecha inicial"
                        maxDate={values.endDate}
                    />
                    <ArrowRightIcon className="h-5 w-5 mx-3" />
                    <InputDate
                        className="w-52 mt-1 relative rounded-md shadow-sm"
                        name="endDate"
                        id="inputDateFinal"
                        placeholder="Fecha final"
                        minDate={values.startDate}

                    />
                </div> 
            </div>
            <div className="col-span-4 flex flex-wrap">
                <InputDropBox
                    options={devicesList}
                    label="Tipo de registro"
                    labelClassName="block text-sm font-medium text-gray-700"
                    className="w-40 mr-4"
                    name="typeLogs"
                />
                <InputDropBox
                    options={orderList}
                    label="Ordernar de forma"
                    labelClassName="block text-sm font-medium text-gray-700"
                    className="w-40"
                    name="orderBy"
                /> 
            </div>
            <div className="col-span-4 flex flex-wrap items-end">
                <InputDropBox
                    options={amountList}
                    label="N° de registros"
                    labelClassName="block text-sm font-medium text-gray-700"
                    className="w-20"
                    name="amountLogs"
                />

                <button
                    type="submit"
                    className="items-center px-3 py-2 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400"
                    disabled={isSubmitting}
                >
                    {isSubmitting ? "Buscando..." : "Aplicar"}
                </button>
            </div>
        </>
    );} ;