import React from "react";
import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from '@headlessui/react';
import { ClipboardListIcon, XIcon, MenuAlt2Icon } from "@heroicons/react/outline";
import { useLocation } from "react-router-dom";
import MenuTabs from "./MenuTabs";

const sidebarNavigation = [
    { name: "Registros", href: "/dashboard/logs", icon: ClipboardListIcon, current: true },
];
  
const NavBar = (props) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [tabs, setTabs] = useState(sidebarNavigation);
    const location = useLocation();

    useEffect(() => {
        handleTab(location.pathname);
    }, [location]);

    const handleTab = (option) =>{
        setTabs([...sidebarNavigation]
            .map(item => item.href === option ? { ...item, current : true } : { ...item, current : false }));
    };


    return (
        <div className="h-screen bg-gray-50 flex overflow-hidden">
            {/* Narrow sidebar */}
            <div className="hidden w-28 bg-gray-900 overflow-y-auto md:block">
                <MenuTabs tabs={tabs}/>
            </div>

            {/* Mobile menu */}
            <Transition.Root show={mobileMenuOpen} as={Fragment}>
                <Dialog as="div" static className="md:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                        </Transition.Child>
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <div className="relative w-60 bg-gray-700 pt-5 pb-4">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-1 right-0 p-1">
                                        <button
                                            type="button"
                                            className="h-8 w-8 flex items-center justify-center focus:outline-none"
                                            onClick={() => setMobileMenuOpen(false)}
                                        >
                                            <XIcon className="h-8 w-8 text-white" aria-hidden="true" />
                                            <span className="sr-only">Close sidebar</span>
                                        </button>
                                    </div>
                                </Transition.Child>
                                <MenuTabs tabs={tabs}/>
                            </div>
                        </Transition.Child>
                        <div className="flex-shrink-0 w-14" aria-hidden="true">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Content area */}
            <div className="flex-1 flex flex-col overflow-hidden">
                <header className="w-full">
                    <div className="sm:hidden relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex">
                        <button
                            type="button"
                            className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500 md:hidden"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                </header>

                {/* Main content */}
                <div className="flex-1 flex items-stretch overflow-hidden">
                    <main className="flex-1 overflow-y-auto">
                        <section
                            aria-labelledby="primary-heading"
                            className="min-w-0 flex-1 h-auto flex flex-col overflow-hidden lg:order-last"
                        >
                            {props.children}
                        </section>
                    </main>
                </div>
            </div>
        </div>
    );
};
export default NavBar;
